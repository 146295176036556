<template>
    <b-row class="rowbg">
        <b-col md="12">
                       <b-card>
                <b-card-title>Videoclips</b-card-title>
                   <silent-box class="galleryitems" :gallery="galleryvideos"></silent-box>

            </b-card>
            <b-card>
                <b-card-title>Foto's</b-card-title>
                <silent-box  :gallery="galleryimages"></silent-box>
               
            </b-card>
 
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Fotofilm',
        data: function () {
            return {
                galleryvideos : [
                
                   
                    {
                       src: 'https://www.youtube.com/embed/4HT4sLyL86E'
                    },
                    {
                        src: 'https://www.youtube.com/embed/S0V7UpHlM2I'
                    },
                    {
                       src: 'https://www.youtube.com/embed/7IdAfQsrcdw'
                    },
                    {
                        src: 'https://www.youtube.com/embed/xBomzL-IupU'
                    },
                    {
                        src: 'https://www.youtube.com/embed/JAK1USlFH6M'
                    },
                    {
                        src: 'https://www.youtube.com/embed/A56C6qTTrmc'
                    },
                    {
                        src: 'https://www.youtube.com/embed/P652hlhJUCM'
                    },
                    {
                        src: 'https://www.youtube.com/embed/n_UMVTtd7fI'
                    },
                    {
                        src: 'https://www.youtube.com/embed/hIuchunSDSs'
                    },
                    {
                        src: 'https://www.youtube.com/embed/nvkMp7pF-4U'
                    },
                ],
                galleryimages: [
                                       {
                        src: this.$imagestore1 + '/website%202018/Ridder-en-de-Prins---In-het-Beatrixtheater.jpg'
                    },
                    {
                        src: this.$imagestore1 + '/website%202018/Ridder-en-de-Prins---Opnames-videoclip.jpg'
                    },
                    {
                        src: this.$imagestore1 + '/website%202018/Ridder-en-de-Prins---Training-en-weer-even-inkomen.jpg'
                    },
                    {
                        src: this.$mediaurl + '/images/2018/07/30/ridder-en-de-prins---pieten-lezen-in-het-grote-boek.jpg'
                    },
                    {           
                        src: this.$imagestore1 + '/website%202018/Ridder-en-de-Prins---Techniek-op-locatie.jpg'
                    },
                    {           
                        src: this.$imagestore1 + '/website%202018/Ridder-en-de-Prins---Kleine-show.jpg'
                    },
                    {
                        src: this.$imagestore1 + '/website%202018/Ridder-en-de-Prins---Proef_uit_de_Pepernotenprinter_01.jpg'
                    },
                    {           
                        src: this.$imagestore1 + '/website%202018/Ridder-en-de-Prins---Proef_uit_de_Pepernotenprinter_02.jpg'
                    },
                    {           
                        src: this.$imagestore1 + '/website%202018/Ridder-en-de-Prins---Proef_uit_de_Pepernotenprinter_03.jpg'
                    },
                    {           
                        src: this.$imagestore1 + '/website%202018/Ridder-en-de-Prins---Proef_uit_de_Pepernotenprinter_04.jpg'
                    }
                ]

            }
        }
    }
</script>

<style>

</style>